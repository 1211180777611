import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Footer from "./views/sections/Footer";
import { default as LoadPage } from "./utils/loadable";
const LandingPage = LoadPage("Landing");

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <div
          style={{
            position: "relative",
            minHeight: "80vh",
          }}
        >
          <div>
            <Route exact path="/" component={LandingPage} />
          </div>
        </div>
        <Redirect to="/" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default AppRouter;
