import React from "react";
import OuroTheme from "./theme";
import AppRouter from "./router";

function App() {
  return (
    <>
      <OuroTheme>
        <AppRouter />
      </OuroTheme>
    </>
  );
}

export default App;
