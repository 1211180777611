import React from "react";
import {
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  Container,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IsMobile } from "../../../utils/mobile";

const useStyles = makeStyles({
  root: {
    bottom: 0,
    width: "100%",
  },
  socialList: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    width: "100%"
  },
  footer: {
    color: "#8F9688",
    "&:hover": {
      color: "#6DA024",
    },
    textDecoration: "none",
  },
  footerNoHover: {
    color: "#8F9688",
  },
  icons: {
    fill: "#8F9688",
    width: "24px",
    "&:hover": {
      fill: "#6DA024",
    },
  },
  whiteIcon: {
    fill: "white",
    width: "24px",
  },
});

const FooterText = ({ link, disableHover, children }) => {
  const classes = useStyles();

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={disableHover ? classes.footerNoHover : classes.footer}
    >
      <Typography
        variant="body2"
        className={disableHover ? classes.footerNoHover : classes.footer}
      >
        {children}
      </Typography>
    </a>
  );
};

const FooterIcon = ({ link, alt, src }) => {
  const classes = useStyles();
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <img alt={alt} src={src} className="close" />
    </a>
  );
};

/**
 * Responsive footer container
 * if the user switch to mobile mode, the footer will not be sticky.
 */
const FooterContainer = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();
  return useMediaQuery(theme.breakpoints.up("md")) ? (
    <Box width="100%" mt="80px" mb="10px" className={classes.root}>
      {children}
    </Box>
  ) : (
    <Box marginTop="20px" width="100%" style={{ backgroundColor: "#fafafa" }}>
      {children}
    </Box>
  );
};

const Footer = () => {
  const classes = useStyles();

  return (
    <Container>
      <FooterContainer>
        <Box mt="40px" mb="20px" px="25px">
          <Grid
            container
            direction={IsMobile() ? "column-reverse" : "row"}
            alignItems="center"
            justify={IsMobile() ? "center" : "space-between"}
          >
            <Grid item>
              <List className={classes.socialList} dense>
              <ListItem>
                <FooterText link="https://github.com/ouro-official/ouro-report/blob/main/ouro_v1.0_signed.pdf">
                    Audit
                  </FooterText>
                </ListItem>
                <ListItem disableGutters>
                  <FooterText link="https://docs.ouro.finance/">
                    Docs
                  </FooterText>
                </ListItem>
                <ListItem>
                  <FooterText link="https://github.com/ouro-official">
                    GitHub
                  </FooterText>
                </ListItem>
                <ListItem >
                  <a
                    href="https://twitter.com/OuroStable"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg width="24" height="24" className={classes.icons}>
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        rx="12"
                        ry="12"
                      />
                      <path
                        style={{ fill: "white" }}
                        d="M9.21545 17.8335C14.874 17.8335 17.9715 13.0284 17.9715 8.86448C17.9715 8.73123 17.9715 8.59799 17.9634 8.45641C18.565 8.01504 19.0854 7.45708 19.5 6.82417C18.9472 7.074 18.3537 7.24056 17.7276 7.32384C18.3618 6.93243 18.8496 6.31617 19.0854 5.575C18.4919 5.94142 17.8333 6.19959 17.126 6.34116C16.565 5.71657 15.7683 5.3335 14.8821 5.3335C13.1829 5.3335 11.8008 6.74922 11.8008 8.48973C11.8008 8.73956 11.8333 8.97274 11.8821 9.20591C9.32927 9.081 7.06098 7.81518 5.54065 5.90811C5.27236 6.3828 5.12602 6.9241 5.12602 7.49872C5.12602 8.58966 5.67073 9.55568 6.49187 10.122C5.9878 10.1053 5.51626 9.96374 5.10163 9.73057C5.10163 9.73889 5.10163 9.75555 5.10163 9.7722C5.10163 11.2962 6.16667 12.5787 7.56504 12.8618C7.30488 12.9368 7.03659 12.9701 6.75203 12.9701C6.55691 12.9701 6.36179 12.9534 6.1748 12.9118C6.56504 14.1693 7.70325 15.077 9.05285 15.102C7.99593 15.9514 6.67073 16.4511 5.23171 16.4511C4.9878 16.4511 4.73577 16.4428 4.5 16.4094C5.85772 17.3088 7.47561 17.8335 9.21545 17.8335Z"
                      />
                    </svg>
                  </a>
                </ListItem>
                <ListItem disableGutters>
                  <a
                    href="https://medium.com/@Ouro"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg width="24" height="24" className={classes.icons}>
                      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.0892 7.20595L17.1213 8.13272C17.0389 8.19451 16.9977 8.29748 17.0183 8.40046V15.2071C16.9977 15.3101 17.0389 15.413 17.1213 15.4748L18.0686 16.4016V16.6076H13.3215V16.4016L14.2998 15.4542C14.4027 15.3616 14.4027 15.3307 14.4027 15.1865V9.68764L11.6842 16.5767H11.3135L8.15217 9.68764V14.3112C8.13158 14.4966 8.19336 14.6922 8.32723 14.8364L9.60412 16.381V16.5767H6V16.381L7.26659 14.8364C7.40046 14.6922 7.46224 14.4966 7.43135 14.3112V8.96682C7.45194 8.81236 7.39016 8.66819 7.27689 8.56522L6.15446 7.20595V7H9.65561L12.3638 12.9519L14.7426 7H18.0892V7.20595Z" />
                    </svg>
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    href="https://discord.gg/e68SZYYXSZ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      className={classes.icons}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="32" height="32" rx="16" />
                      <path
                        d="M24.3303 8.5075C22.7767 7.80325 21.1156 7.29143 19.3789 7C19.1656 7.37361 18.9164 7.87612 18.7446 8.27586C16.8985 8.00687 15.0693 8.00687 13.2572 8.27586C13.0854 7.87612 12.8306 7.37361 12.6154 7C10.8768 7.29143 9.21376 7.80513 7.66019 8.51123C4.52664 13.099 3.67719 17.5729 4.10192 21.9832C6.18025 23.4869 8.19441 24.4004 10.1746 24.9982C10.6635 24.3462 11.0995 23.6532 11.4752 22.9228C10.7598 22.6594 10.0745 22.3344 9.42707 21.9571C9.59884 21.8338 9.76686 21.7049 9.92918 21.5722C13.8782 23.3618 18.1689 23.3618 22.0707 21.5722C22.235 21.7049 22.403 21.8338 22.5728 21.9571C21.9235 22.3362 21.2364 22.6613 20.521 22.9247C20.8966 23.6532 21.3308 24.3481 21.8216 25C23.8036 24.4022 25.8197 23.4888 27.898 21.9832C28.3964 16.8705 27.0467 12.4377 24.3303 8.5075ZM12.0132 19.2709C10.8277 19.2709 9.85555 18.1986 9.85555 16.8929C9.85555 15.5872 10.807 14.5131 12.0132 14.5131C13.2194 14.5131 14.1916 15.5853 14.1708 16.8929C14.1727 18.1986 13.2194 19.2709 12.0132 19.2709ZM19.9867 19.2709C18.8013 19.2709 17.8291 18.1986 17.8291 16.8929C17.8291 15.5872 18.7805 14.5131 19.9867 14.5131C21.193 14.5131 22.1651 15.5853 22.1444 16.8929C22.1444 18.1986 21.193 19.2709 19.9867 19.2709Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </ListItem>
                <ListItem disableGutters>
                  <a
                    href="https://t.me/ourostable"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg className={classes.icons} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0C5.37313 0 0 5.37313 0 11.9872C0 18.6141 5.37313 24 12 24C18.6269 24 24 18.6141 24 11.9872C24 5.37313 18.6269 0 12 0ZM17.9104 7.53518C17.8337 8.64819 15.774 16.9638 15.774 16.9638C15.774 16.9638 15.6461 17.4499 15.1983 17.4627C15.0448 17.4627 14.8401 17.4499 14.6098 17.2324C14.1365 16.8358 13.0618 16.0682 12.0512 15.3646C12.0128 15.403 11.9744 15.4414 11.9232 15.4797C11.693 15.6844 11.3475 15.9787 10.9765 16.3369C10.8358 16.4648 10.6823 16.6055 10.5288 16.7591L10.516 16.7719C10.4264 16.8614 10.3497 16.9254 10.2857 16.9765C9.78678 17.3859 9.73561 17.0405 9.73561 16.8614L10.0043 13.9318V13.9062L10.0171 13.8806C10.0299 13.8422 10.0554 13.8294 10.0554 13.8294C10.0554 13.8294 15.2878 9.17271 15.4286 8.67377C15.4414 8.64819 15.403 8.6226 15.339 8.64819C14.9936 8.76333 8.96802 12.5757 8.30277 12.9979C8.26439 13.0235 8.14925 13.0107 8.14925 13.0107L5.21962 12.0512C5.21962 12.0512 4.8742 11.9104 4.98934 11.5906C5.01493 11.5267 5.05331 11.4627 5.19403 11.3731C5.84648 10.9126 17.194 6.83156 17.194 6.83156C17.194 6.83156 17.5139 6.72921 17.7058 6.79318C17.7953 6.83156 17.8465 6.86994 17.8977 6.99787C17.9104 7.04904 17.9232 7.15139 17.9232 7.26652C17.9232 7.33049 17.9104 7.40725 17.9104 7.53518Z" fill="#8F9688"/>
                    </svg>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              style={{
                marginBottom: IsMobile() ? 20 : 0,
              }}
            >
              <FooterText disableHover>
                2022 All Rights Reserved @Ouro
              </FooterText>
            </Grid>
          </Grid>
        </Box>
      </FooterContainer>
    </Container>
  );
};

export default Footer;
